@font-face {
  font-family: "OpenSans";
  src:
    local("OpenSans"),
    url("@assets/fonts/OpenSans-Regular.eot") format("woff"),
    url("@assets/fonts/OpenSans-Regular.ttf") format("ttf"),
    url("@assets/fonts/OpenSans-Regular.eot") format("eot");
}
body,
html {
  padding: 0;
  margin: 0;
  font-family: "OpenSans", sans-serif;
  font-size: 16px;
  color: #ffffff;
  background: #0c0b0b;
  height: 100vh;
}
.container {
  padding: 10vh 10px;
  margin: 0 auto;
  max-width: 710px;
  position: relative;
}
.logo {
  display: block;
  margin: 13vh auto 36px auto;
  max-height: 100px;
}
.arrow {
  position: absolute;
  top: 12vh;
  left: -60px;
}
.text_box {
  text-align: center;
  max-width: 350px;
  margin: 0 auto;
  letter-spacing: 0.15px;
}
@media (max-width: 1024px) {
  .arrow {
    display: none;
    transform: rotate(90deg);
    left: auto;
    right: 30px;
  }
}
@media (max-width: 768px) {
  .container {
    padding-top: 5vh;
  }
  .arrow {
    transform: rotate(80deg) scale(0.8);
    right: 10px;
  }
}
